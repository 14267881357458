import styled from "styled-components";

export const TitleMain = styled.h1`
  color: #0031ad;
  font-size: 4rem;
  font-family: "Cinzel";
  font-style: normal;
  font-weight: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  width: 100%;
  user-select: none;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;
