import styled from "styled-components";

export const Paragraph = styled.p`
  /* font-size: 1.15rem; */
  font-size: calc(14px + 0.2vw);
  margin: 0.5vw 0 0 0;
  user-select: text;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 2vw;
  }
`;
