import * as React from "react";
import ikona from "../../assets/ikona.png";

export class DisplayMapClass extends React.Component {
  mapRef = React.createRef();

  state = {
    // The map instance to use during cleanup
    map: null,
  };

  componentDidMount() {
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: "1HDDEoqtwKwnem1wHTQF--CSPSESrrXayJ2ewEhIj8A",
    });

    const defaultLayers = platform.createDefaultLayers();

    // Create an instance of the map
    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 54.418456, lng: 18.55906 },
        zoom: 17,
        pixelRatio: window.devicePixelRatio || 1,
        marker: { lat: 54.418456, lng: 18.55906 },
      }
    );

    // const svgMarkup =
    //   '<svg width="24" height="24" ' +
    //   'xmlns="http://www.w3.org/2000/svg">' +
    //   '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    //   'height="22" /><text x="22" y="18" font-size="12pt" ' +
    //   'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    //   'fill="white">Buchalter 2</text></svg>';

    // eslint-disable-next-line
    const icon = new H.map.Icon(ikona),
      coords = { lat: 54.418456, lng: 18.55906 },
      marker = new H.map.Marker(
        coords
        // { icon: icon }
      );

    map.addObject(marker);
    // eslint-disable-next-line
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    // eslint-disable-next-line
    const ui = H.ui.UI.createDefault(map, defaultLayers);

    // map.getViewModel().setLookAtData({
    //   tilt: 45,
    //   heading: 160
    // });

    this.setState({ map });
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    this.state.map.dispose();
  }

  render() {
    return (
      // Set a height on the map so it will display
      <div
        ref={this.mapRef}
        style={{
          height: "400px",
          width: "100%",
          boxShadow: "3px 3px 10px 1px rgba(0, 0, 0, 0.7)",
        }}
      />
    );
  }
}
