import styled from "styled-components";

export const ColumnWrapper = styled.div`
  flex: 6;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  justify-items: center;

  @media (max-width: 768px) {
    margin: 15px 5px;
  }
`;
