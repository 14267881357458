import styled from "styled-components";

export const HeaderWrapper = styled.div`
  padding: 2em 0;
  min-height: 15vh;

  @media (max-width: 768px) {
    padding: 1em 0;
    min-height: 8vh;
  }
`;
