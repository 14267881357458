import React from "react";
import { Container } from "./styled-App";
import { Switch } from "react-router-dom";
import EasyTransition from "react-easy-transition";

import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import Footer from "./Footer/Footer";

import Contact from "./Contact/Contact";
import Services from "./Services/Services";
import Info from "./Info/Info";

export default function App() {
  return (
    <Container>
      <Header />
      <EasyTransition
        path={"/"}
        initialStyle={{ opacity: 0 }}
        transition="opacity 1s ease-in"
        finalStyle={{ opacity: 1 }}
      >
        <Menu />
        <Switch>
          <EasyTransition
            path={"/kontakt"}
            initialStyle={{ opacity: 0 }}
            transition="opacity 0.2s ease-in"
            finalStyle={{ opacity: 1 }}
          >
            <Contact />
          </EasyTransition>
          <EasyTransition
            path={"/uslugi"}
            initialStyle={{ opacity: 0 }}
            transition="opacity 0.2s ease-in"
            finalStyle={{ opacity: 1 }}
          >
            <Services />
          </EasyTransition>
          <EasyTransition
            path={"/"}
            initialStyle={{ opacity: 0 }}
            transition="opacity 0.2s ease-in"
            finalStyle={{ opacity: 1 }}
          >
            <Info />
          </EasyTransition>
        </Switch>

        <Footer />
      </EasyTransition>
    </Container>
  );
}
