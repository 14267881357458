import styled from "styled-components";

export const ListItem = styled.li`
  list-style: square;
  font-weight: normal;
  font-size: calc(14px + 0.2vw);
  padding-top: 0.5vw;
  user-select: text;
  line-height: 1.5;

  @media (max-width: 768px) {
  }
`;
