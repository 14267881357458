import styled from "styled-components";

export const SectionWrapper = styled.div`
  display: flex;
  min-height: 60vh;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;
