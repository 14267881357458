import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuItem = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
  outline: none;
  font-size: 1.5rem;
  user-select: none;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
