import styled from "styled-components";

export const TitleSecondary = styled.h2`
  font-family: Cinzel;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  margin: 0;
  user-select: none;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
