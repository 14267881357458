import React from "react";
import EasyTransition from "react-easy-transition";
import {
  HeaderWrapper,
  TitleMain,
  TitleSecondary,
  TitleTertiary
} from "./styled/index.jsx";

const Header = () => {
  return (
    <EasyTransition
      path={"/"}
      initialStyle={{ opacity: 0 }}
      transition="opacity 0.5s ease-out"
      finalStyle={{ opacity: 1 }}
    >
      <HeaderWrapper>
        <EasyTransition
          path={"/"}
          initialStyle={{ opacity: 0 }}
          transition="opacity 1.5s ease-in"
          finalStyle={{ opacity: 1 }}
        >
          <TitleTertiary>Biuro Rachunkowe</TitleTertiary>
        </EasyTransition>
        <TitleMain>Buchalter 2</TitleMain>
        <EasyTransition
          path={"/"}
          initialStyle={{ opacity: 0 }}
          transition="opacity 1.5s ease-in"
          finalStyle={{ opacity: 1 }}
        >
          <TitleSecondary>Iwona Zakrocka</TitleSecondary>
        </EasyTransition>
      </HeaderWrapper>
    </EasyTransition>
  );
};

export default Header;
