import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  font-family: "Cinzel", serif;
  text-align: center;
  margin: 0 auto;
  width: 75%;
  user-select: none;
  color: rgba(0, 0, 0, 0.75);
  justify-content: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;
