import styled from "styled-components";

export const ImageWrapper = styled.img`
  height: auto;
  max-height: 50vh;
  max-width: 100%;
  border: 1px;
  border-color: #0031ad;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.7);
`;
