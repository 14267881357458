import React from "react";
import styled from "styled-components";
import { Label } from "../UI/Label";
import { SectionWrapper } from "../UI/SectionWrapper";
import { ColumnWrapper } from "../UI/ColumnWrapper";
import { Paragraph } from "../UI/Paragraph";
import { DisplayMapClass } from "./DisplayMapClass";

const ContactParagraph = styled(Paragraph)`
  text-align: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ContactLabel = styled(Label)`
  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin-top: 4vw;
  }
`;

const LinkWrapper = styled.a`
  text-decoration: none;
  color: inherit;
  transition: 0.5s all;
`;

const Contact = () => {
  return (
    <SectionWrapper>
      <ColumnWrapper>
        <div>
          <ContactLabel>Adres</ContactLabel>
          <ContactParagraph>Buchalter 2 Iwona Zakrocka</ContactParagraph>
          <ContactParagraph>Czyżewskiego 25/4</ContactParagraph>
          <ContactParagraph>80-336 Gdańsk</ContactParagraph>
        </div>
        <div>
          <ContactLabel>Email</ContactLabel>
          <ContactParagraph>
            <LinkWrapper href="mailto:buchalter2@interia.pl">
              buchalter2@interia.pl
            </LinkWrapper>
          </ContactParagraph>
        </div>
        <div>
          <ContactLabel>Telefon</ContactLabel>
          <ContactParagraph>(+58) 554-81-10</ContactParagraph>
          <ContactParagraph>(+48) 504-550-155</ContactParagraph>
        </div>
      </ColumnWrapper>
      <ColumnWrapper>
        <DisplayMapClass />
      </ColumnWrapper>
    </SectionWrapper>
  );
};

export default Contact;
