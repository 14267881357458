import React from "react";
import styled from "styled-components";
import { SectionWrapper } from "../UI/SectionWrapper";
import { ColumnWrapper } from "../UI/ColumnWrapper";
import { Label } from "../UI/Label";
import { ListItem } from "./styled/Index";
import { ImageWrapper } from "../UI/ImageWrapper";

import MainPhoto from "../../assets/licencja.jpg";

const ListWrapper = styled.ul`
  text-align: left;

  @media (max-width: 768px) {
    padding-left: 15px;
  }
`;

const SmallColumnWrapper = styled(ColumnWrapper)`
  flex: 2;
  margin: 0;
`;

const Services = () => {
  return (
    <SectionWrapper>
      <ColumnWrapper>
        <Label>Oferowane Usługi:</Label>
        <ListWrapper>
          <ListItem>
            Prowadzenie ksiąg handlowych oraz sprawozdań finansowych zgodnie z
            ustawą o rachunkowości
          </ListItem>
          <ListItem>
            Prowadzenie podatkowej księgi przychodów i rozchodów
          </ListItem>
          <ListItem>
            Rozliczanie ryczałtu od przychodów ewidencjonowanych
          </ListItem>
          <ListItem> Prowadzenie rozliczeń VAT zgodnie z ustawą o VAT</ListItem>
          <ListItem>
            Sporządzenie i składanie deklaracji podatkowych wymaganych prawem
            takich jak: PIT, CIT oraz VAT a także plików JPK
          </ListItem>
          <ListItem>
            Sporządzenie list wynagrodzeń, rozliczanie umów-zleceń, naliczenie
            składek do ZUS oraz zaliczek na PDOF za pracowników i
            zleceniobiorców
          </ListItem>
          <ListItem>
            Sporządzenie deklaracji PIT-4 oraz deklaracji zgłoszeniowych i
            rozliczeniowych za osoby ubezpieczone u zleceniodawcy
          </ListItem>
          <ListItem>
            Sporządzenie deklaracji PIT11/8B za pracowników i osoby zatrudnione
            na podstawie umów cywilno-prawnych
          </ListItem>
          <ListItem>
            Prowadzenie ewidencji środków trwałych, naliczanie odpisów
            amortyzacyjnych
          </ListItem>
        </ListWrapper>
      </ColumnWrapper>
      <SmallColumnWrapper>
        <div>
          <ImageWrapper src={MainPhoto} alt="Buchalter Foto" />
        </div>
      </SmallColumnWrapper>
    </SectionWrapper>
  );
};

export default Services;
