import React from "react";
import styled from "styled-components";

import { FooterWrapper } from "./styled/index.jsx";

const FooterParagraph = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Montserrat;
  user-select: text;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterParagraph>NIP: 584 171 34 45</FooterParagraph>
      <FooterParagraph>Regon: 193111793</FooterParagraph>
      <FooterParagraph>Nr. lic. MF: 13325/2006</FooterParagraph>
    </FooterWrapper>
  );
};

export default Footer;
