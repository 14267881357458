import React from "react";

import { MenuWrapper, MenuItem, MenuItemWrapper } from "./styled/index.jsx";

const Menu = () => {
  return (
    <MenuWrapper>
      <MenuItemWrapper>
        <MenuItem to="/">Informacje</MenuItem>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItem to="/uslugi">Usługi</MenuItem>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItem to="/kontakt">Kontakt</MenuItem>
      </MenuItemWrapper>
    </MenuWrapper>
  );
};

export default Menu;
