import React from "react";
import { SectionWrapper } from "../UI/SectionWrapper";
import { ColumnWrapper } from "../UI/ColumnWrapper";
import { Label } from "../UI/Label";
import { ImageWrapper } from "../UI/ImageWrapper";
import { Paragraph } from "../UI/Paragraph";

import MainHorizontal from "../../assets/main-horizontal.jpg";

const Info = () => {
  return (
    <SectionWrapper>
      <ColumnWrapper>
        <div>
          <ImageWrapper src={MainHorizontal} alt="Buchalter Foto" />
        </div>
      </ColumnWrapper>
      <ColumnWrapper>
        <Label>Biuro Rachunkowe Buchalter 2</Label>
        <Paragraph>
          Licencjonowane biuro rachunkowe Buchalter 2 od 15 lat świadczy
          kompleksowe usługi księgowe w zakresie księgowości zarówno dla osób
          prawnych jak i fizycznych a także rozliczeń podatkowych, VAT oraz kadr
          i płac.
        </Paragraph>
        <Paragraph>
          W ramach realizowanych przez nas usług zapewniamy klientom
          doświadczenie, rzetelności oraz bezpieczeństwo w wykonaniu usług.
        </Paragraph>
        <Paragraph>
          Obsługujemy podmioty o szerokim spektrum działalności ale do każdego
          klienta podchodzimy indywidualnie dostosowując działanie do specyfiki
          rynkowej i wymagań klienta.
        </Paragraph>
        <Paragraph>
          Jesteśmy po to by pomóc w bezpiecznym i zgodnym z prawem prowadzeniem
          biznesu w zakresie rozliczeń instytucjami i urzędami za co ponosimy
          pełna odpowiedzialność. Posiadamy ubezpieczenie OC od prowadzonej
          działalności.
        </Paragraph>
      </ColumnWrapper>
    </SectionWrapper>
  );
};

export default Info;
