import styled from "styled-components";

export const Label = styled.h2`
  font-size: 1.5rem;
  padding: 20px 0 0 0;
  user-select: text;
  font-weight: normal;
  margin: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding-top: 10px;
  }
`;
