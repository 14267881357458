import styled from "styled-components";

export const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 4vh;
  padding: 1rem 0;
  justify-content: space-evenly;
  border-bottom: 2px solid #0031ad;
  border-top: 2px solid #0031ad;
`;
